@import '~antd/dist/antd.less';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo-block {
  display: block;
  height: 64px;
  float: left;
  padding: 10px;
  margin-right: 15px;
}

.logo {
  display: block;
  height: 100%;
}

.ant-descriptions-view .ant-descriptions-item-label {
  width: 150px;
}
@primary-color: #950011;@layout-header-background: #333333;